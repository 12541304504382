<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <success-toast :successMessage="successMessage" @resetSucessMessage="successMessage = null">
        <template v-slot:success-message>
            <span class="pl-2 text-md">{{ successMessage }}</span>
        </template>
    </success-toast>
    <BackArrow
        :pageTitles="{ heading: 'Request for Quote', content: 'BACK TO Request for quote' }"
    />
    <main v-if="quote" class="bg-white mx-32 my-8 pt-10 pb-20 px-10 rounded-lg shadow text-sm">
        <div class="flex justify-between items-center">
            <span class="text-lg leading-9 font-bold capitalize">{{ quote.project_name }}</span>
            <button
                class="text-white font-semibold rounded bg-pillRejectedStatus py-2 px-4 capitalize"
                @click="discardRequest(quote.id)"
            >
                Decline Request
            </button>
        </div>
        <hr class="my-6" />
        <div class="flex text-sm mb-16">
            <div class="w-1/4">
                <h1 class="text-primaryColor capitalize mb-2">Deadline</h1>
                <div>{{ moment(quote.project_deadline).format('D MMM, YYYY') }}</div>
            </div>
            <div class="w-1/4">
                <h1 class="text-primaryColor capitalize mb-2">Date Created</h1>
                <div>{{ moment(quote.created_at).format('D MMM, YYYY') }}</div>
            </div>
            <div class="w-1/4">
                <h1 class="text-primaryColor capitalize mb-2">Status</h1>
                <span
                    v-if="quote.status != 'approved'"
                    class="text-xs text-white bg-pillPendingStatus rounded-full py-1 px-5 uppercase"
                    >{{ quote.status }}</span
                >
                <span
                    v-else
                    class="text-xs text-white bg-pillApprovedStatus rounded-full py-1 px-5 uppercase"
                    >{{ quote.status }}</span
                >
            </div>
        </div>
        <h1 class="text-lg leading-9 font-bold capitalize">Expenditure requests</h1>
        <hr class="my-6" />
        <table class="w-full table-fixed border border-borderColor capitalize mt-4">
            <thead class="font-semibold text-left capitalize">
                <tr class="rounded-md">
                    <th class="w-1/2 border border-borderColor p-3">Expenditure Request</th>
                    <th class="w-1/2 border border-borderColor p-3">Description</th>
                    <th class="w-1/4 border border-borderColor p-3">Project Deadline</th>
                    <th class="w-1/4 border border-borderColor p-3">Quantity</th>
                    <th class="w-1/4 border border-borderColor p-3">Unit Price</th>
                    <th class="w-1/4 border border-borderColor p-3">Taxes</th>
                    <th class="w-1/4 border border-borderColor p-3">Net price</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="request in quote.rfq_expenditure_requests" :key="request.id">
                    <td class="border border-borderColor p-3 capitalize">
                        {{ request.product_name }}
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        {{ request.product_description }}
                    </td>
                    <td class="border border-borderColor p-3 capitalize">
                        {{ moment(quote.project_deadline).format('D MMM, YYYY') }}
                    </td>
                    <td class="border border-borderColor p-3 capitalize">{{ request.quantity }}</td>
                    <td
                        class="border border-borderColor p-3 capitalize"
                        :data-unitprice="request.unit_cost"
                    >
                        {{ request.unit_cost }}
                    </td>
                    <td class="border border-borderColor p-3 capitalize">0.00</td>
                    <td class="border border-borderColor p-3 capitalize">
                        {{ request.total_cost }}
                    </td>
                </tr>
            </tbody>
        </table>
        <section v-if="total" class="flex flex-row-reverse my-10">
            <div>
                <div class="text-xl">
                    <span class="uppercase">total:</span>
                    <span class="font-bold ml-2 bg-interactionBg rounded-md p-3 shadow-sm"
                        >N {{ $filters.currencyUSD(total) }}.00</span
                    >
                </div>
            </div>
        </section>
        <h1 class="text-lg leading-9 font-bold mt-20">Supporting Document & Payment Preference</h1>
        <hr class="my-6" />
        <section class="py-2 grid grid-cols-8 gap-2">
            <div
                v-for="(file, index) in supportDocuments"
                :key="index"
                class="w-28 h-28 cursor-pointer"
            >
                <div
                    @click="openDocument"
                    class="flex items-center justify-center w-full h-full border border-borderColor rounded"
                >
                    <img
                        v-if="imageFileType.includes(file.fileType)"
                        src="@/assets/images/image-format-icon.svg"
                        alt="image icon"
                        :data-fileurl="file.fileUrl"
                        class="w-1/2"
                    />
                    <img
                        v-if="pdfFileType.includes(file.fileType)"
                        src="@/assets/images/pdf-document-icon.svg"
                        alt="pdf document icon"
                        :data-fileurl="file.fileUrl"
                        class="w-1/2"
                    />
                    <img
                        v-if="wordFileType.includes(file.fileType)"
                        src="@/assets/images/word-document-icon.svg"
                        alt="word document icon"
                        :data-fileurl="file.fileUrl"
                        class="w-1/2"
                    />
                    <img
                        v-if="excelFileType.includes(file.fileType)"
                        src="@/assets/images/word-document-icon.svg"
                        alt="word document icon"
                        :data-fileurl="file.fileUrl"
                        class="w-1/2"
                    />
                </div>
                <div class="w-28 text-xs py-3">{{ file.fileName }}</div>
            </div>
            <img
                @click="attachSupportDocument"
                src="@/assets/images/blank-item-icon.svg"
                alt="image icon"
                class="w-28 h-28 cursor-pointer"
            />
            <input @change="handleFileUpload" id="file-input" type="file" multiple class="hidden" />
        </section>
        <div class="relative w-72 mt-14">
            <h1>Payment Preference</h1>
            <input
                v-model="paymentPreference"
                type="text"
                class="block w-96 border border-borderColor rounded resize-none p-3"
            />
        </div>
        <section class="py-12 pr-32 leading-loose">
            <h1 class="capitalize">comment <span class="lowercase">(optional)</span></h1>
            <textarea
                v-model="comment"
                cols="30"
                rows="3"
                class="block w-full border border-borderColor rounded resize-none p-4"
            ></textarea>
            <SubmitButton
                v-if="quote && quote.status.toLowerCase() !== 'approved'"
                @submitRequest="submitRequest"
                buttonText="Submit Quote"
                class="block ml-auto mt-12"
            />
        </section>
    </main>
    <LoadingSpinner v-else />
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import moment from 'moment'
import errorFunc from '@/util/error'

export default {
    name: 'quoteView',
    props: {
        quoteId: String,
    },
    components: { BackArrow },
    data() {
        return {
            quote: null,
            total: 0,
            moment,
            paymentPreference: null,
            comment: null,
            supportDocuments: [],
            errorMessage: null,
            successMessage: null,
            imageFileType: ['png', 'jpg', 'jpeg'],
            pdfFileType: ['pdf'],
            wordFileType: ['docx', 'doc', 'txt'],
            excelFileType: ['xlsx', 'xls', 'csv'],
        }
    },
    async mounted() {
        try {
            const { data } = await Request.getRequest(
                `vendors/request/quote/vendors/bid/${this.quoteId}`
            )

            this.quote = data.data
            this.paymentPreference = this.quote.payment_preference
            this.comment = this.quote.comment
            this.computeTotalValue(this.quote)
            await this.$nextTick(() => this.makeElementsEditable())

            if (this.quote.files && this.quote.files.length) {
                this.quote.files.forEach(file => {
                    this.supportDocuments.push({
                        fileName: file.file,
                        fileType: file.mimetype,
                        fileSize: file.filesize,
                        fileUrl: file.url,
                    })
                })
            }
        } catch (error) {
            errorFunc(error.response, this.$router)
            this.errorMessage = error.response.data.message
        }
    },
    methods: {
        openDocument(event) {
            const fileUrl = event.target.dataset.fileurl
            window.open(`http://devops-procurement-api.suburbanfiberco.com${fileUrl}`, '_blank')
        },
        async discardRequest(rfqId) {
            if (confirm('Are you sure you want to decline this request?')) {
                try {
                    const formdata = new FormData()
                    formdata.append('rfq_id', rfqId)
                    const { status, data } = await Request.postRequest(
                        'declined/rfq/request',
                        formdata
                    )

                    if (status >= 200 && status < 300) {
                        this.successMessage = data.message
                        setTimeout(() => this.$router.push({ name: 'quotes' }), 4500)
                    }
                } catch (error) {
                    errorFunc(error.response, this.$router)
                    this.errorMessage = error.response.data.message
                }
            }
        },
        computeTotalValue(quote) {
            quote.rfq_expenditure_requests.forEach(tick => {
                this.total += Number(tick.total_cost)
            })
        },
        selectEditableFields() {
            return document.querySelectorAll('[data-unitprice]')
        },
        makeElementsEditable() {
            const elements = this.selectEditableFields()

            if (elements.length) {
                elements.forEach(element => {
                    element.setAttribute('contenteditable', true)
                    element.focus()
                    element.style.backgroundColor = '#f2f2f2'
                })
            }
        },
        attachSupportDocument() {
            document.querySelector('#file-input').click()
        },
        handleFileUpload(event) {
            const { files } = event.target

            if (files.length) {
                files.forEach(file => {
                    this.supportDocuments.push({
                        fileName: file.name,
                        fileType: `${file.name.split('.')[1]}`,
                        fileSize: file.size,
                        fileUrl: 'file_url',
                    })
                })
            }
        },
        async submitRequest() {
            try {
                const unitCostArray = []
                const expenditureRequestIdArray = this.quote.rfq_expenditure_requests.map(
                    request => request.id
                )
                const elements = this.selectEditableFields()

                if (elements.length) {
                    elements.forEach(element => {
                        if (element.textContent.includes('.')) {
                            unitCostArray.push(Number(element.textContent.split('.')[0]))
                        } else {
                            unitCostArray.push(Number(element.textContent))
                        }
                    })
                }

                const formData = new FormData()

                for (let i = 0; i < unitCostArray.length; i += 1) {
                    formData.append('unit_price[]', unitCostArray[i])
                }

                formData.append('payment_preference', this.paymentPreference)
                formData.append('rfq_id', this.quoteId)
                formData.append('comment', this.comment)
                formData.append('vendor_id', this.vendorId)
                for (let i = 0; i < expenditureRequestIdArray.length; i += 1) {
                    formData.append('expenditure_request_id[]', expenditureRequestIdArray[i])
                }

                for (let i = 0; i < this.supportDocuments.length; i += 1) {
                    formData.append('support_document[]', this.supportDocuments[i])
                }

                const { status, data } = await Request.postRequest(
                    'vendors/quotation/store',
                    formData
                )

                if (status >= 200 && status < 300) {
                    this.successMessage = data.message
                    setTimeout(() => this.$router.push({ name: 'quotes' }), 4500)
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
